<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <tp-text-field
        v-bind="attrs"
        v-model="computedDateFormatted"
        v-on="on"
        :append-icon="appendIcon"
        :append-outer-icon="appendOuterIcon"
        :background-color="backgroundColor"
        :class="customClass"
        :clearable="clearable"
        :dense="dense"
        :disabled="disabled"
        :label="label"
        :hide-details="hideDetails"
        :outlined="outlined"
        :placeholder="placeholder"
        :prepend-icon="prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        readonly
        :solo="solo"
        :style="customStyle"
        :validate="validate"
      ></tp-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :allowed-dates="allowedDates"
      :min="min"
      :max="max"
      color="primary"
      no-title
      @input="menu = false"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    max: String,
    min: String,
    allowedDates: {
      type: Function
    },
    appendIcon: {
      type: String
    },
    appendOuterIcon: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: [String, Object]
    },
    customStyle: {
      type: [String, Object]
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [Boolean, String]
    },
    label: {
      type: String
    },
    outlined: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    prependIcon: {
      type: String
    },
    prependInnerIcon: {
      type: String
    },
    solo: {
      type: Boolean,
      default: false
    },
    validate: {
      type: [String, Object],
      default: ""
    },
    value: {
      type: String
    }
  },
  data() {
    return {
      date: null,
      dateFormatted: null,
      menu: false
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    }
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
      this.$emit("change", val);
      this.$emit("input", val);
    },
    value(val) {
      this.date = val;
    },
    menu(val) {
      if (val) {
        this.$emit("open-picker");
      } else {
        this.$emit("close-picker");
      }
    }
  },
  created() {
    this.date = this.value;
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const dateTime = new Date(date),
        day = dateTime
          .getDate()
          .toString()
          .padStart(2, "0"),
        month = (dateTime.getMonth() + 1).toString().padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${day}/${month}/${year}`;
    }
  }
};
</script>
